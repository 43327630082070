import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { PhoneOutput } from '@strana-artw/cabinets-common';
export default {
  name: 'ConsultationSection',
  components: {
    PhoneOutput: PhoneOutput
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    template: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    manager: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    managerName: function managerName() {
      var _this$manager, _this$manager2;
      return "".concat((_this$manager = this.manager) === null || _this$manager === void 0 ? void 0 : _this$manager.lastname, " ").concat((_this$manager2 = this.manager) === null || _this$manager2 === void 0 ? void 0 : _this$manager2.name);
    },
    managerPhoto: function managerPhoto() {
      var _this$manager3;
      return (_this$manager3 = this.manager) === null || _this$manager3 === void 0 || (_this$manager3 = _this$manager3.photo) === null || _this$manager3 === void 0 ? void 0 : _this$manager3.aws;
    }
  },
  methods: {
    handleValueChange: function handleValueChange(val) {
      this.value = val;
    }
  }
};